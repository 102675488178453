import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
// import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const copyToClipboard = str => {
  const el = document.createElement('textarea');  // Create a <textarea> element
  el.value = str;                                 // Set its value to the string that you want copied
  el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px';                      // Move outside the screen to make it invisible
  document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0)     // Store selection if found
      : false;                                    // Mark as false to know no selection existed before
  el.select();                                    // Select the <textarea> content
  document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el);                  // Remove the <textarea> element
  if (selected) {                                 // If a selection existed before copying
    document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
    document.getSelection().addRange(selected);   // Restore the original selection
  }
};

const capitalizeFLetter = (input = '') => input ? input[0].toUpperCase() + input.slice(1) : input

class IndexPage extends React.Component {
  state = {
    wikipedia_link: '',
    title_it: '',
    solutions: '',
    solved_text: '',
    hints: [{
      type: 'l1',
      text: ''
    }],
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: capitalizeFLetter(value)
    });
  }

  getCode = () => {
    return `
      \`\`\`
        // Spanish to check
        // Spanish to check (Oct)
        // Russian to check
        // English to check
        // ${this.state.wikipedia_link}
        LEVELNUMBER: {
            id: LEVELNUMBER,
            groupId: 'WORLD',
            bg: '#cccccc',
            fg: '#333',
            title: \`\`,
            title_it: \`${this.state.title_it}\`,
            title_es: \`\`,
            title_ru: \`\`,
            solutions: [${this.state.solutions.split(',').filter(el=>el).map(el => (`\`${el.trim('')}\``))}  ],
            nearMiss: [],
            solvedTexts: [\`\`],
            solvedTexts_it: [\`${this.state.solved_text}\`],
            solvedTexts_es: [\`\`],
            solvedTexts_ru: [\`\`],
            hints: [
              ${this.state.hints.filter(el=>el.text).map(({type, text})=>({
                type: type,
                id: `LEVELNUMBER${type}`,
                message: ``,
                message_it: text,
                message_es: ``,
                message_ru: ``
              })).map(el => JSON.stringify(el, null, 26).replace(/"([a-z_]*)": "([a-z_]*)"/gi,'$1:`$2`'))}
            ],
            condition: { '>=': [{ 'var': 'stats.world1???????.solved' }, ?????????] },
            x: 1,
            y: 0
          },
        \`\`\`
      `
  }

  render() {

    const { intl } = this.props
    const t = (id) => intl.formatMessage({ id })
    const code = this.getCode()

    return (
      <Layout>
        <SEO title={t("suggest.seo_title")} />

        <h1 className="col-12 text-center mb-3 mt-3">{t("suggest.title")}</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <form className="form-group">
                <h3 class="h6">{t("suggest.intro")}</h3>

                <fieldset className="mb-3">
                  <label>{t("suggest.wikipedia")}</label>
                  <input
                    value={this.state.wikipedia_link}
                    name="wikipedia_link"
                    className="form-control"
                    type="text"
                    onChange={this.handleInputChange}
                    placeholder="https://www.wikipedia..."
                  />
                  <span className="help-block small">{t("suggest.description")}</span>
                </fieldset>
                {/* add "This is not the main focus of the article on wikipedia checkbox" */}
                <fieldset className="mb-3">
                  <label>{t("suggest.header")}</label>
                  <input
                    name="title_it"
                    onChange={this.handleInputChange}
                    className="form-control"
                    type="text"
                    placeholder={t("suggest.header_placeholder")}
                  />
                  <span className="help-block small">{t("suggest.header_description")}</span>
                </fieldset>

                <fieldset className="mb-3">
                  <label>{t("suggest.solutions")}</label>
                  <textarea
                    className="form-control"
                    name="solutions"
                    placeholder={t("suggest.solutions_placeholder")}
                    onChange={this.handleInputChange}
                  ></textarea>
                </fieldset>

                <fieldset className="mb-3">
                  <label>{t("suggest.comments")}</label>
                  <textarea
                    name="solved_text"
                    onChange={this.handleInputChange}
                    placeholder={t("suggest.comments_placeholder")}
                    className="form-control"
                    type="text"
                  ></textarea>
                </fieldset>

                <h3 className="mt-5">{t("suggest.hints")}</h3>
                <h6>{t("suggest.hints_description")}</h6>
                <div className="alert alert-info ">
                  <p><span role="img" aria-label="info">⚠️</span>{t("suggest.hints_example")}</p>
                  <ul class="list-unstyled">
                    <li><strong>{t("suggest.hints_example_1")}</strong> <i>{t("suggest.hints_example_1_description")}</i></li>
                    <li><strong>{t("suggest.hints_example_2")}</strong> <i>{t("suggest.hints_example_2_description")}</i></li>
                    <li><strong>{t("suggest.hints_example_3")}</strong> <i>{t("suggest.hints_example_3_description")}</i></li>
                  </ul>
                </div>
                {this.state.hints.map((el, index)=>(
                  <Hint
                    key={index}
                    t={t}
                    type={el.type}
                    number={index + 1}
                    text={el.text}
                    updateType={(type) => {
                      const hints = [...this.state.hints]
                      hints[index].type = type
                      this.setState({ hints })
                    }}
                    handleInputChange={(evt)=>{
                      const hints = [...this.state.hints]
                      hints[index].text = capitalizeFLetter(evt.target.value)
                      if (this.state.hints[this.state.hints.length-1].text) {
                        hints.push({ type:'l1' })
                      }
                      this.setState({hints})
                    }}
                  />
                ))}

              </form>
            </div>
            <div className="col-md-4">
              <button className="btn btn-primary btn-block" onClick={() => copyToClipboard(code)}>{t("suggest.copy_code")}</button>
              <pre>
                {code}
              </pre>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}



const Hint = ({ type, text, number, updateType, handleInputChange, t }) => (
  <fieldset className="mb-3">
    <label>{t("suggest.add_hint")} {number} </label>
    <br />
    <div className="mb-3">
      <button
        onClick={() => updateType('l1')}
        type="button"
        className={`btn ${type === 'l1' ? 'btn-primary' : 'btn-secondary'}`}
      >
        {t("suggest.add_hint_obvious")}
      </button>
      &nbsp;
      <button
        onClick={() => updateType('l2')}
        type="button"
        className={`btn ${type === 'l2' ? 'btn-primary' : 'btn-secondary'}`}
      >
        {t("suggest.add_hint_helpful")}
      </button>
      &nbsp;
      <button
        onClick={() => updateType('l3')}
        type="button"
        className={`btn ${type === 'l3' ? 'btn-primary' : 'btn-secondary'}`}
      >
        {t("suggest.add_hint_very_helpful")}
      </button>
    </div>

    {type === 'l1' && <p>{t("suggest.add_hint_obvious_description")} <i>{t("suggest.add_hint_obvious_example")}</i></p>}
    {type === 'l2' && <p>{t("suggest.add_hint_helpful_description")} <i>{t("suggest.add_hint_helpful_example")}</i></p>}
    {type === 'l3' && <p>{t("suggest.add_hint_very_helpful_description")} <i>{t("suggest.add_hint_very_helpful_example")}</i></p>}

    <input
      value={text}
      onChange={handleInputChange}
      className="form-control"
      type="text"
    />

  </fieldset>
)



export default injectIntl(IndexPage)
